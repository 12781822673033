<template>
  <v-container fluid>
    <v-row dense>
      <v-dialog
          v-model="dialog"
          max-width="350">
        <v-card>
          <v-card-title  class="font-size16 white--text my-0 py-0" style="background-color:#a51717; height:40px !important;">
          {{ $t('create-resident') }}
          </v-card-title>
          <v-card-text class="font-size15 d-flex flex-column mt-2">
            {{ $t('are-you-sure-the-resident') }} "{{resident?.residentFirstName}} {{resident?.residentLastName}}" {{ $t("is-not-mapped") }} ?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small @click="dialog = false">
              {{$t('cancel')}}
            </v-btn>
            <v-btn class="white--text" small color="chips" @click="confirmCreate">
              {{$t('create')}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
          v-model="deleteDialog"
          max-width="350">
        <v-card>
          <v-card-title  class="font-size16 white--text my-0 py-0" style="background-color:#a51717; height:40px !important;">
            {{ $t('delete-ecp-resident-mapping') }}
          </v-card-title>
          <v-card-text class="font-size15 d-flex flex-column mt-2">
            {{ $t('are-you-sure-you-want-to-delete-this-resident-mapping') }}?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small @click="deleteDialog = false">
              {{$t('cancel')}}
            </v-btn>
            <v-btn class="white--text" small color="chips" @click="confirmDeletion">
              {{$t('delete')}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- TODO:  With Update Table -->
      <v-col cols="6">
        <v-card elevation="5" :loading="false">
          <div class="d-flex justify-space-between elevation-3">
            <div class="d-flex align-center">
              <v-img :src="ecp" class="ml-2" max-height="21" max-width="22"></v-img>
              <div class="text-left my-2 ml-2 mb-2 font-weight-bold font-size16">{{$t('with-update')}}</div>
            </div>
            <div>
              <v-text-field
                  single-line
                  outlined
                  class="py-1 px-1"
                  prepend-inner-icon="mdi-magnify"
                  hide-details
                  dense
                  v-model="searchEcpPatient"
                  :label="$t('search')"
              ></v-text-field>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="mt-3">
            <v-radio-group class="small"  v-model="selectedEcpUser">
              <v-data-table
                  :loading="loading"
                  :headers="ecpHeaders"
                  :items="items.usersNeedToBeMapped"
                  :search="searchEcpPatient"
                  height="27vh"
                  fixed-header
                  class="mt-n5 pointer"
                  :footer-props="footerProps"
                  :items-per-page="-1"
                  :no-data-text="$t('no-data-available')"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <v-tooltip color="primary" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <td style="height:30px !important;" class="table-font-size px-2 py-0"  v-bind="attrs" v-on="on">
                          <div class="d-flex">
                            <v-icon
                                style="font-size:12px !important;"
                                class="mr-1"
                                x-small
                                :class="getStatusIcons(item.residentStatus)"
                            >
                              mdi-checkbox-blank-circle
                            </v-icon>
                            <v-img
                                class="mr-2"
                                :src="PatientBlack"
                                max-width="13px"
                                max-height="20px"
                            />
                            {{ item.residentFirstName }} {{ item.residentLastName }}
                          </div>
                        </td>
                      </template>
                      <span>{{ item?.residentStatusDescription || '' }}</span>
                    </v-tooltip>
                    <td style="height:30px !important;"  class="table-font-size px-2 py-0">
                      <v-chip  label pill x-small color="primary">
                        {{calculateAge(item.residentBirthDate)}}
                      </v-chip>
                    </td>
                    <td style="height:30px !important;" class="table-font-size px-2 py-0">
                      {{ item.residentBirthDate.split('T')[0] }}
                    </td>
                    <td  style="height:30px !important;" class="table-font-size px-2 py-0">{{(item.residentGender)}}</td>
                    <td style="height:30px !important;" class="table-font-size pl-8 py-0">
                      <v-radio :value="item" ></v-radio>
                    </td>
                    <td style="height:30px !important;" class="table-font-size pl-8 py-0">
                      <v-btn  x-small color="primary" class="text-capitalize" @click="createResident(item)">{{$t('create')}}</v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-radio-group>
          </div>
        </v-card>
      </v-col>
      <!-- TODO:  Not Mapped Table -->
      <v-col cols="6" class="mb-1">
        <v-card elevation="5" :loading="false">
          <div class="d-flex justify-space-between elevation-3">
            <div class="d-flex align-center">
              <v-img :src="logoDrawer" class="ml-2" max-height="21" max-width="22"></v-img>
              <div class="text-left my-2 ml-2 mb-2 font-weight-bold font-size16">{{$t('not-mapped-patients')}}</div>
            </div>
            <div>
              <v-text-field
                  single-line
                  outlined
                  class="py-1 px-1"
                  prepend-inner-icon="mdi-magnify"
                  hide-details
                  dense
                  v-model="searchNotMappedPatient"
                  :label="$t('search')"
              ></v-text-field>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="mt-3">
            <v-radio-group v-model="selectedNotMappedUser" class="small">
              <v-data-table
                  :loading="loading"
                  :headers="staffHeaders"
                  :items="items.notMappedUsers"
                  :search="searchNotMappedPatient"
                  height="27vh"
                  fixed-header
                  class="pointer mt-n5"
                  :footer-props="footerProps"
                  :items-per-page="-1"
                  :no-data-text="$t('no-data-available')"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td style="height:30px !important;" class="table-font-size px-2 py-0">
                      <div class="d-flex">
                        <v-icon style="font-size:12px !important;"   class="mr-1"   :style="item.status === 1 ? 'color: #09CB09' : ''">mdi-checkbox-blank-circle</v-icon>
                        <v-img  class="mr-2" :src="PatientBlack" max-width="13px" max-height="20px"/>{{ item.firstName }} {{item.lastName}}
                      </div>
                    </td>
                    <td style="height:30px !important;" class="table-font-size px-2 py-0">
                      <v-chip  label pill x-small color="primary"> {{calculateAge(item.birthdate)}}</v-chip>
                    </td>
                    <td style="height:30px !important;" class="table-font-size px-2 py-0">
                      {{ item.birthdate }}
                    </td>
                    <td style="height:30px !important;"  class="table-font-size px-2 py-0">{{(item.gender === 'F' ? $t('female'): $t('male'))}}</td>
                    <td style="height:30px !important;" class="table-font-size pl-8 py-0">
                      <v-radio :value="item"></v-radio>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-radio-group>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-btn v-if="selectedEcpUser !== null && selectedNotMappedUser !== null" @click="mapUser" color="primary2" class="full-width my-2">
      <span style="text-transform: capitalize;" class="font-size15 text-color-white">Map User</span>
    </v-btn>
    <v-row dense class="my-0 py-0">
      <!--   TODO: MAPPED USERS   -->
      <v-col cols="12">
        <v-card elevation="5" :loading="false">
          <div class="d-flex justify-space-between elevation-3">
            <div class="d-flex align-center">
              <v-img class="ml-2" :src="PatientBlack" max-width="13px" max-height="20px"/>
              <div class="text-left my-2 ml-2 mb-2 font-weight-bold font-size16">{{$t('mapped-patients')}}</div>
            </div>
            <div>
              <v-text-field
                  single-line
                  outlined
                  class="py-1 px-1"
                  prepend-inner-icon="mdi-magnify"
                  hide-details
                  dense
                  v-model="searchMappedPatient"
                  :label="$t('search')"
              ></v-text-field>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="mt-3">
            <v-data-table
                :loading="loading"
                :headers="staffHeaders"
                :items="items.mappedUsers"
                :search="searchMappedPatient"
                height="33vh"
                fixed-header
                class="mt-n3 pointer"
                :footer-props="footerProps"
                :items-per-page="-1"
                :no-data-text="$t('no-data-available')"
            >
              <template v-slot:item="{item}">
                <tr>
                  <td style="height:30px !important;" class="table-font-size px-2 py-0">
                    <div class="d-flex align-center">
                      <v-img  class="mr-2" :src="PatientBlack" max-width="13px" max-height="20px"/>
                      <span>{{ item.firstName }} {{item.lastName}}</span>
                    </div>
                  </td>
                  <td style="height:30px !important;" class="table-font-size px-2 py-0">
                    <v-chip  label pill x-small color="primary"> {{calculateAge(item.birthdate)}}</v-chip>
                  </td>
                  <td style="height:30px !important;" class="table-font-size px-2 py-0">
                    {{ (item.birthdate) }}
                  </td>
                  <td   style="height:30px !important;" class="table-font-size px-2 py-0">{{item.gender === 'M' ? $t('male') : $t('female')}}</td>
                  <td  style="height:30px !important;" class="table-font-size text-left pl-8 py-0">
                    <v-icon @click="openDeleteDialog(item)" size="18" color="primary">mdi mdi-delete</v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { ecp, patientBlack, logoDrawer } from '@/assets';
import MappingRepository from '@/data/repositories/v1/MappingRepository.js';
import UsersRepository from '@/data/repositories/v1/UsersRepository.js';

export default {
  data () {
    return {
      dialog: false,
      deleteDialog: false,
      resident: null,
      patientToDelete: null,
      PatientBlack: patientBlack,
      loading: false,
      openDialog: false,
      selectedEcpUser: null,
      selectedNotMappedUser: null,
      searchEcpPatient: '',
      searchNotMappedPatient: '',
      searchMappedPatient: '',
      footerProps: { 'items-per-page-options': [-1, 5, 10, 50, 100] },
      items: {
        notMappedUsers: [],
        mappedUsers: [],
        usersNeedToBeMapped: [],
      },
      ecpHeaders: [
        { text: this.$t('patient-name'), value: 'residentFirstName', class: 'px-0 py-0 pl-2 height15-black' },
        { text: this.$t('age'), value: 'birthdate', class: 'px-0 py-0 pl-4  height15-black' },
        { text: this.$t('birthdate'), value: 'residentBirthDate', class: 'px-0 py-0 pl-2 height15-black' },
        { text: this.$t('gender'), value: 'residentGender', class: 'px-0 py-0 pl-2 height15-black' },
        { text: '', value: '', class: 'px-0 py-0 height15-black', width: '50' },
        { text: '', value: '', class: 'px-0 py-0 height15-black', width: '50' },
      ],
      staffHeaders: [
        { text: this.$t('patient-name'), value: 'firstName', class: 'px-0 py-0 pl-2 height15-black' },
        { text: this.$t('age'), value: 'birthdate', class: 'px-0 py-0 pl-3 height15-black' },
        { text: this.$t('birthdate'), value: 'birthdate', class: 'px-0 py-0 pl-2 height15-black' },
        { text: this.$t('gender'), value: 'gender', class: 'px-0 py-0 pl-2 height15-black' },
        { text: '', value: '', class: 'px-0 py-0 height15-black', width: '50' },
      ],
    };
  },
  computed: {
    logoDrawer () {
      return logoDrawer;
    },
    ecp () {
      return ecp;
    },
    ...mapState({
      userData: (state) => state.authentication.userData,
      selectedHospitalId: (state) => state.filterbar.hospitalId,
      medicalInstitution: (state) => state.filterbar.medicalInstitution,
    }),
    ...mapGetters({
      roleName: 'authentication/getRole',
      userSettings: 'authentication/getUserSettings',
    }),
  },
  created () {
    if (this.roleName === 'HOSPITAL_ADMIN') {
      this.getUsersForMapping();
    } else {
      if (this.selectedHospitalId !== null) {
        this.getUsersForMapping();
      }
    }
  },
  watch: {
    selectedHospitalId: {
      handler () {
        this.getUsersForMapping();
      },
      deep: true,
    },
  },
  mounted () {
  },

  methods: {
    openDeleteDialog (item) {
      this.deleteDialog = true;
      this.patientToDelete = item.id;
    },
    confirmDeletion () {
      MappingRepository.deleteMappedUser(this.patientToDelete).then(() => {
        this.getUsersForMapping();
        this.searchEcpPatient = '';
        this.searchNotMappedPatient = '';
        this.searchMappedPatient = '';
        this.selectedNotMappedUser = null;
        this.selectedEcpUser = null;
        this.deleteDialog = false;
      });
    },
    getStatusIcons (status) {
      switch (status) {
        case 'Active':
          return 'color-green';
        default:
          return '';
      }
    },
    confirmCreate () {
      UsersRepository.createResident(this.resident.id)
      .then(() => {
        this.getUsersForMapping();
        this.dialog = false;
      });
    },
    createResident (item) {
      this.dialog = true;
      this.resident = item;
    },
    calculateAge (dateString) {
      var today = new Date();
      var birthDate = new Date(dateString?.split('T')[0]);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    getUsersForMapping () {
      this.loading = true;

      let selectedHospitalId = null;
      if (this.roleName === 'HOSPITAL_ADMIN') {
        selectedHospitalId = this.userData.hospitalAssignedData.id;
      } else {
        selectedHospitalId = this.selectedHospitalId;
      }

      MappingRepository.getMappedEcpUsersForHospital(selectedHospitalId).then(response => {
            this.items = response || [];
            this.loading = false;
          });
    },
    mapUser () {
      this.loading = true;

      let selectedHospitalId = null;
      let selectedOrganizationId = null;
      if (this.roleName === 'HOSPITAL_ADMIN') {
        selectedHospitalId = this.userData.hospitalAssignedData.id;
        selectedOrganizationId = this.userData.organizationId;
      } else {
        selectedHospitalId = this.selectedHospitalId;
        selectedOrganizationId = this.medicalInstitution;
      }

      const body = {
        ecpUserId: this.selectedEcpUser?.residentId || 'string',
        mappedUserId: this.selectedNotMappedUser?.id || 0,
        mappedHospitalId: selectedHospitalId,
        mappedOrganizationId: selectedOrganizationId,
        firstName: this.selectedEcpUser?.residentFirstName,
        lastName: this.selectedEcpUser?.residentLastName,
        birthdate: this.selectedEcpUser?.residentBirthDate,
      };

      MappingRepository.mapUser(body)
          .then(response => {
            if (response.resFlag) {
              this.getUsersForMapping();
              this.selectedNotMappedUser = null;
              this.selectedEcpUser = null;
              this.searchEcpPatient = '';
              this.searchNotMappedPatient = '';
              this.searchMappedPatient = '';
            } else this.loading = false;
          });
    },
    deleteMappedUser (item) {
      MappingRepository.deleteMappedUser(item.id).then(() => {
        this.getUsersForMapping();
        this.searchEcpPatient = '';
        this.searchNotMappedPatient = '';
        this.searchMappedPatient = '';
        this.selectedNotMappedUser = null;
        this.selectedEcpUser = null;
      });
    },

  },
};
</script>

<style>
.v-messages{
  display:none !important;
}

.small i {
  font-size: 18px !important;
}

.small [class*="__ripple"] {
  left: 0;
  display:none;
}
</style>
